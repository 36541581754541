@use '_breakpoints.scss' as breaks;
@use 'sass:map';

@mixin style($theme) {
  // Override default layout using a one-column layout always
  body {
    .welcome {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "main"
        "picture";
      color: map.get($theme, 'white-1');
      background: map.get($theme, 'black-1');

      @include breaks.for-phone-only {
        margin-top: 1em;
      }

      &[id=picture] {
        grid-area: picture;
        width: 100%;
      }

      main {
        grid-area: main;
        padding: 10em 5em;

        h1 {
          color: map.get($theme, 'white-1');
          display: block; // reset
          padding: 1rem 0;
          font-family: map.get($theme, 'font-family-bold');

          @include breaks.for-phone-only {
            text-align: center;
            margin: 0;
            padding: 1rem 0;
          }
        }

        h2 {
          text-transform: uppercase;
          font-size: 1.2rem;
          padding: 1rem 0;
          margin: 0;
          strong {
            color: map.get($theme, 'font-color-strong');
            font-style: normal;
            font-weight: bold;
          }
        }

        p {
          padding: 1rem 0;
        }

        #sms {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 1rem;
          padding: 0 1rem;

          p {
            font-weight: bold;
            padding: 0;
          }

          border: 1px solid map.get($theme, 'primary');
        }

        a.next-button {
          text-align: center;
          vertical-align: center;
          font-weight: bold;
          font-size: 1em;
          box-sizing: border-box;
          display: block;
          padding: 0.8em 0;
          height: 3em;
          border-radius: 2em;
        }

        @include breaks.for-phone-only {
          padding: 1em 2em;
        }
      }

      @include breaks.for-phone-only {
        grid-template-columns: 1fr;
        grid-template-areas:
          "picture"
          "main";
      }
    }
  }

  .welcome-footer {
    grid-area: footer;
    display: flex;
    justify-content: flex-end;

    .next-button:last-child {
      margin-left: 1em;
    }
  }
}
