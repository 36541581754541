@use 'sass:map';

@mixin button {
  width: 100%;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 0.2em;

  // Ensure sole buttons are placed on the right
  &:last-child {
    margin-left: auto;
  }
}

@mixin style($theme) {
  a {
    text-decoration: none;
    color: map.get($theme, 'primary');

    &:hover {
      text-decoration: underline;
    }

    &:hover, &:visited, &:active {
      color: map.get($theme, 'primary');
    }
  }

  .button-panel {
    grid-area: footer;
    display: flex;
    justify-content: space-between;
  }

  .off-button {
    @include button;
    color: map.get($theme, 'gray');
    box-shadow: none;
    background-color: map.get($theme, 'primary-off');
  }

  .next-button {
    @include button;
    color: map.get($theme, 'white-1');
    background-color: map.get($theme, 'gradient-start');
    background: linear-gradient(to top, map.get($theme, 'gradient-start'), map.get($theme, 'gradient-stop-1'));
  }

  a.next-button, a.back-button {
    &:focus, &:visited, &:active {
      color: map.get($theme, 'white-1');
    }

    &:hover {
      text-decoration: none;
    }
  }
}
